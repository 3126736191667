import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import url from 'url-browser';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router';
import { withTranslation } from 'react-i18next';

import { logInFinish } from '../../oidc-middleware/actions/login';
import * as notify from '../utils/notify';
import { ErrorBoundary } from '../hoc/errorboundary';
import { getLS } from '../../main/utils/localStorageProvider';

@ErrorBoundary((props) => {
  return `Callback, ${props.t('incompatible_state')}`;
})
@withTranslation()
@connect(
  null,
  dispatch => {
    return bindActionCreators({
      logInFinish: logInFinish,
    }, dispatch);
  }
)
export default class Callback extends Component {
  componentDidMount() {
    const { t } = this.props;
    const querystring = this.props.location.search;
    const query = url.parse("/" + querystring, true).query;
    if (query && query.error === "USER_UNAUTHORISED_FOR_THIS_APPLICATION") {
      notify.error(t('authorization_error_notification_title'), t('authorization_error_notification_body'));
    }
    else if (query.error !== 'login_required')
      this.props.logInFinish({ data: query });
  }

  render() {
    const pathname = getLS('redirect_url_pathname', true);
    const search = getLS('redirect_url_search', true);

    if (pathname)
      return (
        <Redirect to={{
          pathname: pathname,
          search: search
        }} />
      );
    else
      return (
        <Redirect to="/" />
      );
  }
}

Callback.propTypes = {
  location: PropTypes.object,
  logInFinish: PropTypes.func, //HOC
  t: PropTypes.func //HOC
};
