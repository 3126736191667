import React, { Component } from 'react';
//import { Nav, Collapse } from 'reactstrap';
//import { Route, Link } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { NavbarBrand, Navbar } from 'reactstrap';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import { __env } from '../../envloader';
//import Login from '../../main/containers/login';
import videochat_ikona_top from '../../assets/img/icons/videochat_ikona_top.svg';
import pionier_logo_top from '../../assets/img/icons/pionier_logo_top.svg';
import up2u_logo_top from '../../assets/img/icons/up2u_logo_top.svg';

@withTranslation()
export default class TopBar extends Component {

  render() {
    return (
      <Navbar color="faded" expand>
        <NavbarBrand tag={Link} to="/">
          <img src={videochat_ikona_top} alt="Videochat"/>
          <span>Videochat</span>
        </NavbarBrand>

        <a href={__env.PC_FRONTPAGE_URL} className="centered-logo">
          <img className="up2u" src={up2u_logo_top} alt="Pionier logo"/>
          <img src={pionier_logo_top} alt="Pionier logo"/>
        </a>

        {/*temp removed - TODO: restored when needed*/}
        {/*<Collapse navbar>*/}
        {/*  <Nav className="ml-auto" navbar >*/}
        {/*    <Route strict path='/' render={props => (*/}
        {/*      <Login location={props.location} />*/}
        {/*    )} />*/}
        {/*  </Nav>*/}
        {/*</Collapse>*/}
      </Navbar>
    );
  }
}

TopBar.propTypes = {
  t: PropTypes.func //HOC
};
