import React from 'react';

import '../styles/index.scss';
import TopBar from './topbar';
import MainContent from './mainContent';

const root = () => (
  <div>
    <TopBar />
    <MainContent />
  </div>
);

export default root;
