import React, { Component } from 'react';
import { Redirect } from 'react-router';

import { ErrorBoundary } from '../../main/hoc/errorboundary';
import * as notify from '../utils/notify';
import i18n from '../utils/i18nConfigProvider';
import { clearLS, setLS } from '../utils/localStorageProvider';

@ErrorBoundary(props => props.t('error_boundary.components.LogoutCallback'), false)
export default class LogoutCallback extends Component {

  componentDidMount() {
    //Clear all fields in localStorage
    clearLS();
    setLS('logout_in_progress', true);
    notify.success(i18n.t('logout_success_notification_title'), i18n.t('logout_success_notification_body'));
  }

  render() {
    return (
      <Redirect to="/"/>
    );
  }
}
