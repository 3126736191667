import React, { Suspense } from 'react';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import ReduxToastr from 'react-redux-toastr';

import Root from '../vc-landing/containers/root';
import configureStore from './stores/configure-store';
import { AxiosInstancesContext } from './hoc/utils/axios-context';
import { axiosInstances } from './hoc/utils/axios-utils';

const { history, store } = configureStore();

export default () => (
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <div>
        <Suspense fallback={<div></div>}>
          <AxiosInstancesContext.Provider value={axiosInstances}>
            <Root />
          </AxiosInstancesContext.Provider>
          <ReduxToastr
            timeOut={0}
            newestOnTop={false}
            preventDuplicates
            removeOnHover={false}
            removeOnHoverTimeOut={10000}
            position="top-right"
            transitionIn="fadeIn"
            transitionOut="fadeOut"
            //progressBar
            closeOnToastrClick={false}
          />
        </Suspense>
      </div>
    </ConnectedRouter>
  </Provider>
);
