import { __env } from '../../envloader/index';

export const setLS = (key, value) => {
  localStorage.setItem(__env.LOCAL_STORAGE_PREFIX_APP_NAME + '_' + key, value);
};

export const getLS = (key, withRemove = false) => {
  const lsItem = localStorage.getItem(__env.LOCAL_STORAGE_PREFIX_APP_NAME + '_' + key);
  if (withRemove) {
    localStorage.removeItem(__env.LOCAL_STORAGE_PREFIX_APP_NAME + '_' + key);
  }
  return lsItem;
};

export const removeLS = (key) => {
  localStorage.removeItem(__env.LOCAL_STORAGE_PREFIX_APP_NAME + '_' + key);
};

export const clearLS = () => {
  localStorage.clear();
};

export const clearOwnLS = () => {
  let keyTab = [];
  for (let i = 0; i < localStorage.length; ++i) {
    let key = localStorage.key(i);
    if (key.includes(__env.LOCAL_STORAGE_PREFIX_APP_NAME)) {
      keyTab.push(key);
    }
  }
  keyTab.forEach(key => { localStorage.removeItem(key); });
};
