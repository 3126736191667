import React, { Component } from 'react';
import { Button, Form, Row, Col } from 'reactstrap';

import videochat from '../../assets/img/icons/videochat.svg';
import * as notify from '../../main/utils/notify';

function getServerURL() {
  const servers = [
    "vc01.classroom.pionier.net.pl",
    "vc02.classroom.pionier.net.pl",
    "vc03.classroom.pionier.net.pl"
  ];
  const randomNumber = Math.floor(Math.random() * servers.length);
  return servers[randomNumber];
}

function getRandomRoom() {
  const timestamp = Date.now();
  const randomNumber = Math.floor(Math.random() * Math.pow(10, 10));
  return randomNumber.toString(36) + timestamp.toString(36).slice(4);
}

function generateURL() {
  const serverURL = getServerURL();
  const room = getRandomRoom();
  return 'https://' + serverURL + '/' + room;
}

export default class MainView extends Component {
  state = {
    url: generateURL()
  };

  copyLink = () => {
    document.getElementById('room').select();
    document.execCommand('copy');
    notify.success("Skopiowano link", "Link do Twojego pokoju jest już w schowku. Wyślij go pozostałym uczestnikom!");
  };

  goToLink = () => {
    window.location.href = this.state.url;
  };

  render() {
    return (
      <div className="mainView">
        <div className="mainLogo">
          <img src={videochat} alt="videochat"/><br/>
          <h1>Videochat</h1>
        </div>

        <Form>
          <div className="mainForm">
            <Row noGutters>
              <Col sm="12" lg={{ size: 6, offset: 3 }} xl={{ size: 4, offset: 4 }}>
                <div className="hint">Adres Twojego pokoju:</div>
              </Col>
              <Col sm="12" lg={{ size: 6, offset: 3 }} xl={{ size: 4, offset: 4 }}>
                <input id='room' type='text' className="mx-2" value={this.state.url} readOnly />
                <div className="hint">Skopiuj powyższy link i przekaż go pozostałym uczestnikom spotkania. Zachowaj go, abyś mógł używać go później.</div>
              </Col>
              <Col id="copyCol" sm="12" lg="3" xl="4">
                <Button className="mx-2" onClick={this.copyLink}>Kopiuj link</Button>
              </Col>
            </Row>
          </div>

          <Button className="mt-5" onClick={this.goToLink}>Przejdź do pokoju</Button>
          <div className="hint">Jeśli chcesz, przejdź do pokoju i rozpocznij spotkanie już teraz!</div>
        </Form>
      </div>
    );
  }
}
