import * as axios from 'axios';

import { __env } from '../../envloader';
import { getLS, removeLS, setLS } from './localStorageProvider';
import * as notify from './notify';
import i18n from './i18nConfigProvider';

//Paths of login and logout callback to use where needed
export const loginCallbackPath = '/ui/auth/login-callback/';
export const logoutCallbackPath = '/ui/auth/logout-callback/';

//if config is missing it will be pulled from well-known or localstorage
export function getAuthConfig() {
  let promise;
  const config = JSON.parse(getLS('endpoint_config'));
  if (!checkIfConfigIsPresent() && (!config || config.length === 0)) {
    promise = new Promise((resolve) => {
      axios.get(__env.AUTH_OPENID_PROVIDER + '/.well-known/openid-configuration').then(function (response) {
        setLS('endpoint_config', JSON.stringify(response.data));
        resolve(updateConfigVariables());
      }).catch(function (error) {
        notify.error(i18n.t('authentication_error_notification_title'), error.message);
        removeLS('login_in_progress');
        throw error;
      });
    });
  }
  else {
    promise = new Promise((resolve) => {
      resolve(updateConfigVariables());
    });
  }
  return promise;
}

//this functino checks if all env variables are provided
function checkIfConfigIsPresent() {
  if ((!__env.AUTH_AUTHORIZATION_ENDPOINT || 0 === __env.AUTH_AUTHORIZATION_ENDPOINT.length)
    || (!__env.AUTH_TOKEN_ENDPOINT || 0 === __env.AUTH_TOKEN_ENDPOINT.length)
    || (!__env.AUTH_ISSUER || 0 === __env.AUTH_ISSUER.length)
    || (!__env.AUTH_JWKS_URI || 0 === __env.AUTH_JWKS_URI.length)
    || (!__env.AUTH_CHECK_SESSION_IFRAME || 0 === __env.AUTH_CHECK_SESSION_IFRAME.length)
    || (!__env.AUTH_END_SESSION_ENDPOINT || 0 === __env.AUTH_END_SESSION_ENDPOINT.length)) {
    return false;
  }
  else {
    return true;
  }
}

export function checkIfLoginIsPossible() {
  if (!__env.AUTH_OPENID_PROVIDER || 0 === __env.AUTH_OPENID_PROVIDER.length) {
    checkIfConfigIsPresent();
  }
  else {
    return true;
  }
}

//this function retrieves needed variables from local storage
function updateConfigVariables() {
  let result = {};
  const config = JSON.parse(getLS('endpoint_config'));
  if (!__env.AUTH_AUTHORIZATION_ENDPOINT || 0 === __env.AUTH_AUTHORIZATION_ENDPOINT.length) {
    result.AUTH_AUTHORIZATION_ENDPOINT = config.authorization_endpoint;
  }
  else {
    result.AUTH_AUTHORIZATION_ENDPOINT = __env.AUTH_AUTHORIZATION_ENDPOINT;
  }
  if (!__env.AUTH_TOKEN_ENDPOINT || 0 === __env.AUTH_TOKEN_ENDPOINT.length) {
    result.AUTH_TOKEN_ENDPOINT = config.token_endpoint;
  }
  else {
    result.AUTH_TOKEN_ENDPOINT = __env.AUTH_TOKEN_ENDPOINT;
  }
  if (!__env.AUTH_ISSUER || 0 === __env.AUTH_ISSUER.length) {
    result.AUTH_ISSUER = config.issuer;
  }
  else {
    result.AUTH_ISSUER = __env.AUTH_ISSUER;
  }
  if (!__env.AUTH_JWKS_URI || 0 === __env.AUTH_JWKS_URI.length) {
    result.AUTH_JWKS_URI = config.jwks_uri;
  }
  else {
    result.AUTH_JWKS_URI = __env.AUTH_JWKS_URI;
  }
  if (!__env.AUTH_CHECK_SESSION_IFRAME || 0 === __env.AUTH_CHECK_SESSION_IFRAME.length) {
    result.AUTH_CHECK_SESSION_IFRAME = config.check_session_iframe;
  }
  else {
    result.AUTH_CHECK_SESSION_IFRAME = __env.AUTH_CHECK_SESSION_IFRAME;
  }
  if (!__env.AUTH_END_SESSION_ENDPOINT || 0 === __env.AUTH_END_SESSION_ENDPOINT.length) {
    result.AUTH_END_SESSION_ENDPOINT = config.end_session_endpoint;
  }
  else {
    result.AUTH_END_SESSION_ENDPOINT = __env.AUTH_END_SESSION_ENDPOINT;
  }
  return result;
}
