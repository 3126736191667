import React from 'react';
import ReactDOM from 'react-dom';
import { I18nextProvider } from 'react-i18next';

import MainApp from './main/index';
import i18n from './main/utils/i18nConfigProvider';
import { initializeSentry } from './main/utils/logger';
import { __env } from './envloader';
import { getLS } from './main/utils/localStorageProvider';
import { logInRedirect } from './oidc-middleware/utils/login-utils';
import { checkIfLoginIsPossible } from './main/utils/AuthConfigProvider';

initializeSentry();

if (
  checkIfLoginIsPossible()
  && __env.SILENT_AUTHENTICATION
  && !getLS('login_in_progress', true)
  && !getLS('logout_in_progress', true)
)
  logInRedirect('none');
else ReactDOM.render(
  <I18nextProvider i18n={i18n}><MainApp /></I18nextProvider>,
  document.getElementById('root')
);

