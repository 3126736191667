import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import MainView from './mainView';
//import AnonymousView from './anonymousView';
import Callback from '../../main/containers/callback';
import LogoutCallback from '../../main/containers/logoutCallback';
import { getGV } from '../../main/utils/globalVariablesProvider';
import { loginCallbackPath, logoutCallbackPath } from '../../main/utils/AuthConfigProvider';

@withTranslation()
@connect(
  state => ({
    // Kind of fake - used only to refresh content after login and logout
    loginStore: state.login.get('loginData'),
  })
)
export default class MainContent extends Component {
  renderContent = () => {
    if (!getGV('accessToken')) {
      //User not logged in
      return (
        <Switch>
          <Route exact strict path='/' component={MainView} /> {/*temp removed - TODO: restored AnonymousView when needed*/}
          <Route path={ loginCallbackPath } exact component={Callback} />
          <Route path={ logoutCallbackPath } exact component={LogoutCallback} />
        </Switch>
      );
    }
    else {
      //User logged in
      return (
        <Switch>
          <Route exact strict path='/' component={MainView} />
        </Switch>
      );
    }
  };

  render() {
    return (
      <div className="main-content text-center">
        {this.renderContent()}
      </div>
    );
  }
}

MainContent.propTypes = {
  t: PropTypes.func, //HOC
};
